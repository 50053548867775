<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-profile
            :save_loading="loading"
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :user_type="page.model">
          <template v-slot:below_photo>
            <v-row>
              <v-col v-if="record.person && !record.person.user" class="text-center">
                <base-create-user
                  :person_id="record.person.id"
                  :user_type="page.model"
                  :emailaddress="getEmailaddress"
                  :person="record.person"
                  @refresh="load"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="record.person && record.person.user" class="text-center">
                <base-notification
                  @refresh="load"
                  :user_id="record.person.user.id"
                  :recipient="record.person.name"
                  :notifications="record.person.user.notifications"
                  :phonenumber="record.person.mobilenumber"/>
              </v-col>
            </v-row>
            <v-row v-if="record.person && record.person.user">
              <v-col class="text-center">
                <v-btn @click="resetPassword" :loading="reset_password_loading" small>
                  {{ $store.getters.translate("reset_your_password") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="record.person && record.person.user">
              <v-col class="text-center">
                <v-btn @click="deleteUser" :loading="reset_delete_user_loading" small color="error">
                  {{ $store.getters.translate("delete_account") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <v-footer-panel ref="footer_panel" slot="footer" :model="page.name"/>
        </base-profile>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="certificates_table" :page="page" :parent_module_id="record.id" relation_name="certificates" @edit="editCertificate" @change="load"/>
          <base-table-includable ref="dossiers_table" :page="page" :parent_module_id="record.id" relation_name="dossiers" @edit="editDossier" @change="load"/>
          <base-table-includable ref="exams_table" :page="page" :parent_module_id="record.id" relation_name="exams" @edit="editExam" @change="load"/>
<!--          <base-table-includable ref="courses_table" :page="page" :parent_module_id="record.id" relation_name="courses" @edit="editCourse" @change="load"/>-->
          <base-table-includable ref="companies_table" :page="page" :parent_module_id="record.id" relation_name="companies" @edit="editCompany" @change="load"/>
          <base-table-includable ref="contacts_table" :page="page" :parent_module_id="record.id" relation_name="contacts" @create="createContact" @edit="editContact" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.name" :model_id="record.id" ref="edit_note" @document_preview="preview" @refresh="load" :model_type="$models[page.name]"
        :assessors="record.assessors" :exams="record.exams" :companies="record.companies" :dossiers="record.dossiers"/>
    <create-note
        :module_name="page.name"
        modal_name="create_note"
        :assessors="record.assessors"
        :exams="record.exams"
        :companies="record.companies"
        :dossiers="record.dossiers"
        :model_type="$models[page.name]"
        :model_id="record.id"
        ref="create_note"
        @refresh="load"/>
    <create-person modal_name="create_person" :module_id="record.id" action="create_contact" ref="create_person" user_type="contact" @refresh="load"/>
    <edit-certificate modal_name="edit_certificate" ref="edit_certificate" @refresh="load" :key="key"/>
    <document-edit modal_name="document_edit" ref="document_edit"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseProfile from "../../components/commonComponents/BaseProfile";
import EditNote from "../notes/Edit";
import CreateNote from "../notes/Create";
import CreatePerson from "../people/Create";
import EditCertificate from "../certificates/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import DocumentEdit from "../documents/Edit";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    DocumentEdit,
    BaseTableIncludable,
    EditCertificate,
    BaseProfile,
    CreateNote,
    CreatePerson,
    EditNote,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("candidates"),
        name: "candidates",
        model: "candidate",
      },
      record: {
        custom_fields: {}
      },
      loading: false,
      tab: 0,
      key: 0,
      send_login_credentials_loading: false,
      reset_password_loading: false,
      reset_delete_user_loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.record = response.data;
          this.record.assessors = [];
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          if (this.record.exams) {
            this.record.exams = helpFunctions.getLocationsAndRooms(this.record.exams, true, true);
            this.record.exams.forEach((exam) => {
              exam.assessors.forEach((assessor) => {
                if (!this.record.assessors.find((item) => item.id == assessor.id)) {
                  this.record.assessors.push({
                    id: assessor.id,
                    name: assessor.name,
                  });
                }
              });
            });
          }
          if (this.record.courses) {
            this.record.courses = helpFunctions.getLocationsAndRooms(this.record.courses, true, true);
          }
          this.record.dossiers.forEach((dossier) => {
            if(dossier.is_closed) {
              dossier.name = this.$store.getters.translate("closed") + ': ' + dossier.name;
            }
          });
          this.updateIncludableTables();
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateIncludableTables() {
      this.$refs.certificates_table.records = this.record.certificates;
      this.$refs.dossiers_table.records = this.record.dossiers;
      this.$refs.exams_table.records = this.record.exams;
      //this.$refs.courses_table.records = this.record.courses;
      this.$refs.companies_table.records = this.record.companies;
      this.$refs.contacts_table.records = this.record.contacts;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.certificates_table.selected = [];
      this.$refs.dossiers_table.selected = [];
      this.$refs.exams_table.selected = [];
      //this.$refs.courses_table.selected = [];
      this.$refs.companies_table.selected = [];
      this.$refs.contacts_table.selected = [];
      this.$refs.notes_table.selected = [];
      this.$refs.footer_panel.record = this.record;
      this.$refs.footer_panel.load();
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.$toasted.success(this.$store.getters.translate("success"));
                this.load();
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
            .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
            .then(() => {
              this.$router.push("/" + this.page.name);
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    resetPassword() {
      this.reset_password_loading = true;
      if (this.record.person && this.record.person.user) {
        this.$http
          .post(this.$store.getters.appUrl + "v2/resetpassword", {
            email: this.record.person.user.email,
          })
          .then(() => {
            this.reset_password_loading = false;
            this.$toasted.success(this.$store.getters.translate("password_reset_requested"));
            this.$emit("refresh");
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.reset_password_loading = false;
          });
      }
      else {
        this.$toasted.error(this.$store.getters.translate("no_user"));
      }
    },
    deleteUser() {
      this.reset_delete_user_loading = true;
      this.record.person.delete_user = true;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/people/" + this.record.person.id, this.record.person)
        .then(() => {
          this.reset_delete_user_loading = false;
          this.load();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editNote(item) {
      this.$refs.edit_note.openModal('edit_note');
      this.$refs.edit_note.load(item.id);
    },
    editCompany(item) {
      this.$router.push("/companies/" + item.id);
    },
    createNote() {
      this.$refs.create_note.openModal('create_note');
    },
    editExam(item) {
      this.$router.push("/exams/" + item.id);
    },
    editCourse(item) {
      this.$router.push("/courses/" + item.id);
    },
    editContact(item) {
      this.$router.push("/contacts/" + item.id);
    },
    editCertificate(item) {
      this.$refs.edit_certificate.id = item.id;
      this.$refs.edit_certificate.openModal('edit_certificate');
      this.$refs.edit_certificate.load();
    },
    editDossier(item) {
      this.$router.push("/dossiers/" + item.id);
    },
    createContact() {
      this.$refs.create_person.openModal('create_contact');
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
  },
  computed: {
    getEmailaddress() {
      if (this.record.person.emailaddresses.length > 0) {
        return this.record.person.emailaddresses[0].emailaddress;
      }
      return null;
    },
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>
